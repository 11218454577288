import React from 'react'
import Img from 'gatsby-image'
import PageHeading from '../Page/PageHeading'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const HomePanelInner = ({ text, image, link, index }) => {
  const handleMouseEnter = index => {
    document
      .querySelector(`.sticky-bar-link-${index}`)
      .classList.add('opacity-50')
  }

  const handleMouseLeave = index => {
    document
      .querySelector(`.sticky-bar-link-${index}`)
      .classList.remove('opacity-50')
  }

  return (
    <>
      {text && (
        <div
          className="rte text-2xl lg:text-5xl leading-tight mt-10 md:mt-0 mb-25 md:mb-35 md:pr-gs"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      )}
      {link && image.localFile && (
        <AniLink
          to={link.url}
          title={image.alt}
          fade
          className="block mb-5 lg:mb-15"
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
        >
          <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt} />
        </AniLink>
      )}
      {!link && image.localFile && (
        <div className="mb-5 lg:mb-15">
          <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt} />
        </div>
      )}
    </>
  )
}

const HomePanel = ({ heading, text, image, link, index, last }) => {
  return (
    <div className="w-full md:w-1/3 md:pl-gs md:mt-10 lg:mt-15 relative">
      <PageHeading heading={heading} bg="white" link={link} />
      <div className="h-full flex flex-col md:justify-between group px-ogs md:px-0 relative z-10">
        <HomePanelInner text={text} image={image} link={link} index={index} />
        {!last && <div className="flush-both h-hh bg-white md:hidden" />}
      </div>
    </div>
  )
}

const HomePanels = ({ page }) => {
  return (
    <div className="bg-smoke">
      <div className="md:px-ogs">
        <div className="flex flex-wrap md:-ml-gs">
          <HomePanel
            index={0}
            heading={page.heading_primary}
            text={page.body_primary.html}
            image={page.image_primary}
            link={page.link_primary}
          />
          <HomePanel
            index={1}
            heading={page.heading_secondary}
            text={page.body_secondary.html}
            image={page.image_secondary}
            link={page.link_secondary}
          />
          <HomePanel
            index={2}
            heading={page.heading_tertiary}
            text={page.body_tertiary.html}
            image={page.image_tertiary}
            link={page.link_tertiary}
            last
          />
        </div>
      </div>
    </div>
  )
}

export default HomePanels
