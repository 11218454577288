import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageLead from '../components/Page/PageLead'
import PageHero from '../components/Page/PageHero'
import PageStickyBar from '../components/Page/PageStickyBar'
import HomePanels from '../components/Home/HomePanels'

const IndexPage = ({ location, data }) => {
  const page = data.prismicHome.data
  return (
    <Layout headerColor="grey" hasHero>
      <SEO
        title={page.meta_title || page.title}
        description={page.meta_description || ''}
        location={location}
      />
      <PageLead lead={page.lead.html} color="peach" />
      <PageHero image={page.hero} />
      <div className="relative pt-hh">
        <PageStickyBar
          links={[
            { heading: page.heading_primary, link: page.link_primary },
            { heading: page.heading_secondary, link: page.link_secondary },
            { heading: page.heading_tertiary, link: page.link_tertiary },
          ]}
        />
        <HomePanels page={page} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    prismicHome {
      data {
        title
        body_primary {
          html
        }
        body_secondary {
          html
        }
        body_tertiary {
          html
        }
        heading_primary
        heading_secondary
        heading_tertiary
        hero {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_primary {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_secondary {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_tertiary {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        link_primary {
          uid
          url
          link_type
        }
        link_secondary {
          uid
          url
          link_type
        }
        link_tertiary {
          uid
          url
          link_type
        }
        lead {
          html
        }
        meta_description
        meta_title
      }
    }
  }
`
